import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { PromiseStatuses } from "../../lib/utils"
import { format } from "date-fns";
import { act } from "react";
import { GetAllPersonalManagementDTO, PersonalManagementDTO, PersonalManagementUpdateDTO } from "./dataService/dto";
import { GetAllRappLegaleResponseDTO } from "../clienti/dto";
import { NewPersonalManagementService } from "./dataService/service";

interface PersonalManagementState {
    selectedTab: number
    currentPersonalManagementId?: string

    // REQUEST
    personalManagementUpdateRequest: PersonalManagementUpdateDTO

    // STATUS
    personalManagementCreationStatus: PromiseStatuses
    personalManagementUpdateStatus: PromiseStatuses
    getAllPersonalManagementResourcesStatus: PromiseStatuses
    getByIdPersonalManagementResourcesStatus: PromiseStatuses

    // RESPONSE
    getAllPersonalManagementResourcesResponse?: GetAllPersonalManagementDTO
    getByIdPersonalManagementResourcesResponse?: PersonalManagementDTO
   
}

const initialState: PersonalManagementState = {
    selectedTab: 0,

    // REQUEST
    personalManagementUpdateRequest: {
        jobs: []
    },

    // STATUS
    personalManagementCreationStatus: 'idle',
    personalManagementUpdateStatus: 'idle',
    getAllPersonalManagementResourcesStatus: 'idle',
    getByIdPersonalManagementResourcesStatus: 'idle',

}

export const GetAllPersonalManagement = createAsyncThunk(
    'PM/GetAll',
    async (request, thunkApi): Promise<GetAllPersonalManagementDTO> => {
        const personalManagementService = NewPersonalManagementService()

        return personalManagementService.GetAllPersonalManagementResources().catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetByIdPersonalManagement = createAsyncThunk(
    'PM/GetByIdPersonalManagement',
    async (id: string, thunkApi): Promise<PersonalManagementDTO> => {
        const personalManagementService = NewPersonalManagementService()

        return personalManagementService.GetByIdPersonalManagementResources(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const PMCreation = createAsyncThunk(
    'PM/create',
    async (request, thunkApi): Promise<string> => {
        const personalManagementService = NewPersonalManagementService()

        return personalManagementService.CreatePersonalManagementResource().catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const PMUpdate = createAsyncThunk(
    'PM/update',
    async (request: {body: PersonalManagementUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const personalManagementService = NewPersonalManagementService()

        return personalManagementService.UpdatePersonalManagementResource(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)



const PersonalManagementSlice = createSlice({
    name: 'personalManagement/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setCurrentPersonalManagementId: (state, action) => {
            state.currentPersonalManagementId = action.payload
        },

        // REQUEST
        setPMJobs: (state, action) => {
            state.personalManagementUpdateRequest.jobs = action.payload
        },
        
        // STATUS
        resetPersonalManagementCreationStatus: (state) => {
            state.personalManagementCreationStatus = 'idle'
        },
        resetPersonalManagementUpdateStatus: (state) => {
            state.personalManagementUpdateStatus = 'idle'
        },
        resetGetAllPersonalManagementResourcesStatus: (state) => {
            state.getAllPersonalManagementResourcesStatus = 'idle'
        },

        resetGetByIdPersonalManagementResourcesStatus: (state, action) => {
            state.getByIdPersonalManagementResourcesStatus = action.payload
            console.log(' stato ', state.getByIdPersonalManagementResourcesStatus)
        }

    },
    extraReducers(builder) {
        builder
            // GET ALL
            .addCase(GetAllPersonalManagement.pending, (state) => {
                state.getAllPersonalManagementResourcesStatus = 'loading'
            })
            .addCase(GetAllPersonalManagement.fulfilled, (state, action) => {
                state.getAllPersonalManagementResourcesStatus = 'successfully'
                state.getAllPersonalManagementResourcesResponse = action.payload
            })
            .addCase(GetAllPersonalManagement.rejected, (state) => {
                state.getAllPersonalManagementResourcesStatus = 'failed'
            })

            // UPDATE
            .addCase(PMUpdate.pending, (state) => {
                state.personalManagementUpdateStatus = 'loading'
            })
            .addCase(PMUpdate.fulfilled, (state) => {
                state.personalManagementUpdateStatus = 'successfully'
            })
            .addCase(PMUpdate.rejected, (state) => {
                state.personalManagementUpdateStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetByIdPersonalManagement.pending, (state) => {
                state.getByIdPersonalManagementResourcesStatus = 'loading'
            })
            .addCase(GetByIdPersonalManagement.fulfilled, (state, action) => {
                state.getByIdPersonalManagementResourcesStatus = 'successfully'
                state.getByIdPersonalManagementResourcesResponse = action.payload
            })
            .addCase(GetByIdPersonalManagement.rejected, (state) => {
                state.getByIdPersonalManagementResourcesStatus = 'failed'
            })

            // CREATION
            .addCase(PMCreation.pending, (state) => {
                state.personalManagementCreationStatus = 'loading'
            })
            .addCase(PMCreation.fulfilled, (state, action) => {
                state.personalManagementCreationStatus = 'successfully'
                state.currentPersonalManagementId = action.payload
            })
            .addCase(PMCreation.rejected, (state) => {
                state.personalManagementCreationStatus = 'failed'
            })
    },
})

export const {
    setSelectedTab,
    setCurrentPersonalManagementId,

    // REQUEST
    setPMJobs,

    // STATUS
    resetGetAllPersonalManagementResourcesStatus,
    resetPersonalManagementUpdateStatus,
    resetPersonalManagementCreationStatus,
    resetGetByIdPersonalManagementResourcesStatus

} = PersonalManagementSlice.actions

export default PersonalManagementSlice.reducer