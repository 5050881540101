import { ReactElement, ReactNode } from "react"
import { ActivityIcon } from "../../icons/activity"
import { CalendarIcon } from "../../icons/calendar"
import { DatabaseIcon } from "../../icons/database"
import { HomeIcon } from "../../icons/home"
import { RocketIcon } from "../../icons/rocket"
import { SettingsIcon } from "../../icons/settings"
import { UsersIcon } from "../../icons/users"
import { GestPersonaleIcon } from "../../icons/gestPersonaleIcon"

interface MenuItemDetails {
    label: string;
    subMenu?: boolean;
    subMenuItem?: SubMenuItem[]
}

interface SubMenuItem {
    label: string;
    link: string;
    icon?: ReactNode
}

export enum MenuItems {
    DASHBOARD = 'DASHBOARD',
    USERS = 'USERS',
    CLIENTI = 'CLIENTI',
    TECNICO_AZIENDALE = 'TECNICO_AZIENDALE',
    GESTIONE_PERSONALE = 'GESTIONE_PERSONALE',

}

export const MenuItemsLabelMap = new Map<MenuItems, MenuItemDetails>([
    [MenuItems.DASHBOARD, { label: 'Dashboard' }],
    [MenuItems.USERS, { label: 'Utenti' }],
    [MenuItems.CLIENTI, { label: 'Clienti' }],
    [MenuItems.TECNICO_AZIENDALE, { label: 'Tecnico Aziendale' }],
    [
        MenuItems.GESTIONE_PERSONALE,
        {
            label: 'Gestione Personale',
            subMenu: true,
            subMenuItem: [
                { label: 'Elenco Personale', link: '/gestionePersonale', },
                { label: 'Formazione', link: '/formazione' }
            ]
        }
    ],
])

export const MenuItemsHrefMap = new Map<MenuItems, string>([
    [MenuItems.DASHBOARD, '/'],
    [MenuItems.USERS, '/users'],
    [MenuItems.CLIENTI, '/clienti'],
    [MenuItems.TECNICO_AZIENDALE, '/tecnico-aziendale'],
    [MenuItems.GESTIONE_PERSONALE, '/gestionePersonale'],
])

export const PathMenuItemMap = new Map<string, MenuItems>([
    ['dashboard', MenuItems.DASHBOARD],
    ['users', MenuItems.USERS],
    ['clienti', MenuItems.CLIENTI],
    ['tecnico_aziendale', MenuItems.TECNICO_AZIENDALE],
])

export function MenuItemsIconsMap(menuItem: MenuItems, color: string, size: number): ReactElement {
    const map = new Map<MenuItems, ReactElement>([
        [MenuItems.DASHBOARD, <HomeIcon color={color} size={size} />],
        [MenuItems.USERS, <UsersIcon color={color} size={size} />],
        [MenuItems.CLIENTI, <UsersIcon color={color} size={size} />],
        [MenuItems.TECNICO_AZIENDALE, <UsersIcon color={'#fff'} size={size} />],
        [MenuItems.GESTIONE_PERSONALE, <GestPersonaleIcon color={'#fff'} size={size} />],
    ])

    return (map.get(menuItem)!)
}