import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import Button from "../../../ui/molecules/button"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { addODInstance, GetAllOtherData, GetOtherDataById, OtherDataDelete, resetGetAllOtherDataStatus, resetGetOtherDataByIdStatus, resetODInstances, resetOtherDataCreationRequest, resetOtherDataDeleteStatus, resetOtherDataErrors, resetOtherDataUpdateRequest, setODInstances, setOtherDataIdToUpdate, setPMId, setUpdateOtherDataCommitmentStatement, setUpdateOtherDataProtocolIdEntry } from "./slice"
import { PopupForm } from "../../../ui/organisms/popupForm"
import { OtherDataPopupAdd } from "./boxPopupOptions/add"
import { OtherDataDTO } from "./dataService/dto"
import { OtherDataPopupEdit } from "./boxPopupOptions/edit"
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus } from "../slice"

export default function BoxOtherData() {
    const dispatch = useAppDispatch()
    const otherDataState = useAppSelector(state => state.PMOtherData)
    const PMState = useAppSelector(state => state.personalManagement)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [openPopupEdit, setOpenPopupEdit] = useState<boolean>(false)

    const onEditHandler = (od: OtherDataDTO) => {
        dispatch(setUpdateOtherDataProtocolIdEntry(od.protocolIdEntry))
        dispatch(setUpdateOtherDataCommitmentStatement(od.commitmentStatement))
        dispatch(setOtherDataIdToUpdate(od.id))
        setOpenPopupEdit(true)
    }

    useEffect(() => {
        if(PMState.currentPersonalManagementId) {
            dispatch(setPMId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
        }
    }, [PMState.currentPersonalManagementId])

    useEffect(() => {
        dispatch(resetODInstances())
        if (PMState.getByIdPersonalManagementResourcesStatus === 'successfully' && 
            PMState.getByIdPersonalManagementResourcesResponse?.otherDataIds) {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.otherDataIds.length; index++) {
                dispatch(GetOtherDataById(PMState.getByIdPersonalManagementResourcesResponse?.otherDataIds[index].toString()))
            }
        }
    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(otherDataState.getOtherDataByIdStatus === 'successfully' &&
            otherDataState.getOtherDataByIdResponse !== undefined
        ) {
            dispatch(resetGetOtherDataByIdStatus())
            dispatch(addODInstance(otherDataState.getOtherDataByIdResponse))
        }
    }, [otherDataState.getOtherDataByIdStatus])

    useEffect(() => {
        if(otherDataState.otherDataDeleteStatus === 'successfully') {
            dispatch(resetOtherDataDeleteStatus());
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [otherDataState.otherDataDeleteStatus])

    return (
        <div style={{padding: '24px'}}>
            <div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
                    <span id="title-eval-tab">Documenti</span>
                    <Button 
                        size="md" 
                        iconPosition="off" 
                        variant="outline" 
                        color="orange" 
                        label="Aggiungi Documenti" 
                        onClick={() => setOpenPopup(true)}
                    />
                </div>

                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px'}}>RIFERIMENTO CURRICULUM</th>
                            <th>DICHIARAZIONE DI IMPEGNO</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {otherDataState.otherDataInstances.map(od =>
                            <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                            key={od.id}
                            >
                                <td className="text-neutral-700">
                                    {od.protocolIdEntry}
                                </td>
                                <td className="text-neutral-700">
                                    {od.commitmentStatement}
                                </td>
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">

                                        <button onClick={() => {
                                            onEditHandler(od)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-5 flex align-items-center">
                                            <button onClick={() => dispatch(OtherDataDelete(od.id!))}>
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
            {
                openPopup && (
                    <PopupForm title="Aggiungi Documenti" close={() => {
                        dispatch(resetOtherDataErrors())
                        dispatch(resetOtherDataCreationRequest())
                        setOpenPopup(false)
                        }}>
                        <OtherDataPopupAdd close={() => setOpenPopup(false)} />
                    </PopupForm>
                )
            }
            {
                openPopupEdit && (
                    <PopupForm title="Modifica Documenti" close={() => {
                        dispatch(resetOtherDataErrors())
                        dispatch(resetOtherDataUpdateRequest())
                        setOpenPopupEdit(false)
                        }}>
                        <OtherDataPopupEdit close={() => setOpenPopupEdit(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}