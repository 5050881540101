import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import Button from "../../../ui/molecules/button"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { PopupForm } from "../../../ui/organisms/popupForm"
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus } from "../slice"
import { FunzioniPopupAdd } from "./boxPopupFunzioni/add"
import { FunzioniPopupEdit } from "./boxPopupFunzioni/edit"
import { DeleteFunction, GetFunctionById, addFunctionsInstance, resetCreationFunction, resetFunctionDeleteStatus, resetFunctionsInstance, resetGetFunctionByIdStatus, resetUpdateFunction, setPMId, setUpdateRequest } from "./slice"
import { activityOptions, attivita_bio, categorie_prodotti, formatterDate, normativa, optionsFormatter, regioniList } from "../../../utils"

export default function BoxFunzioni() {
    const dispatch = useAppDispatch()
    const functionsState = useAppSelector(state => state.PMFunctions)
    const PMState = useAppSelector(state => state.personalManagement)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [openPopupEdit, setOpenPopupEdit] = useState<boolean>(false)

    useEffect(() => {
        if (PMState.currentPersonalManagementId) {
            dispatch(resetFunctionsInstance())
            dispatch(setPMId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
        }
    }, [PMState.currentPersonalManagementId])

    useEffect(() => {
        dispatch(resetFunctionsInstance())
        if (functionsState.functionCreationStatus === 'successfully' && PMState.currentPersonalManagementId) {
            dispatch(resetCreationFunction('idle'))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
        }

    }, [functionsState.functionCreationStatus])

    useEffect(() => {
        dispatch(resetFunctionsInstance())
        if (functionsState.functionDeleteStatus === 'successfully' && PMState.currentPersonalManagementId) {
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
            dispatch(resetFunctionDeleteStatus())
        }

    }, [functionsState.functionDeleteStatus])

    useEffect(() => {
       
        if (functionsState.functionUpdateStatus === 'successfully' && PMState.currentPersonalManagementId) {
            dispatch(resetUpdateFunction('idle'))
            dispatch(resetFunctionsInstance())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
        }

    }, [functionsState.functionUpdateStatus])

    useEffect(() => {

        dispatch(resetFunctionsInstance())
        if (PMState.getByIdPersonalManagementResourcesStatus === 'successfully' && PMState.getByIdPersonalManagementResourcesResponse?.functionIds) {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.functionIds.length; index++) {
                dispatch(GetFunctionById(PMState.getByIdPersonalManagementResourcesResponse?.functionIds[index].toString()))
            }
        }

    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if (functionsState.getFunctionByIdStatus === 'successfully' && PMState.getByIdPersonalManagementResourcesResponse) {
            dispatch(addFunctionsInstance(functionsState.GetFunctionById))
            dispatch(resetGetFunctionByIdStatus('idle'))
        }
    }, [functionsState.getFunctionByIdStatus])

    return (
        <div style={{ padding: '24px' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <span id="title-eval-tab">Funzioni</span>
                    <Button
                        size="md"
                        iconPosition="off"
                        variant="outline"
                        color="orange"
                        label="Aggiungi Funzione"
                        onClick={() => setOpenPopup(true)}
                    />
                </div>

                <table id="customers-table" className="w-[100%] summaryTable">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px' }}>Funzioni Assegnate</th>
                            <th>Data Inizio</th>
                            <th>Data Fine</th>
                            <th>Norma</th>
                            <th>Attività</th>
                            <th>Categoria</th>
                            <th>Aree Operative</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {functionsState.functionsInstances.map(rowFunction =>
                            <tr
                                className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                                key={rowFunction.id}
                            >
                                <td className="text-neutral-700">
                                    {optionsFormatter(rowFunction.assignedFunction!, activityOptions)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(rowFunction.startingFunction)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(rowFunction.endingFunction)}
                                </td>
                                <td className="text-neutral-700">
                                    {optionsFormatter(rowFunction.norm!, normativa)}
                                </td>
                                <td className="text-neutral-700">
                                    {
                                        rowFunction.functionActivities?.map((activity) => {
                                            if (rowFunction.norm === 'NORMA_BIO') {
                                                return <span> ({activity.bioActivity}) {optionsFormatter(activity.bioActivity!, attivita_bio)}</span>

                                            } else if (rowFunction.norm === 'GLOBAL_GAP') {
                                                return <span> {activity.ggStandard}</span>
                                            }
                                        }
                                        )
                                    }
                                </td>
                                <td className="text-neutral-700">
                                    {rowFunction.functionActivities?.map((activity) => {
                                        if (rowFunction.norm === 'NORMA_BIO') {
                                            if (activity?.bioCategories && Array.isArray(activity.bioCategories) && activity.bioCategories.length > 0) {
                                                return activity.bioCategories.map((category, index) => (
                                                    activity.bioCategories ? (
                                                        <span key={index}>
                                                            {"(" + category + ") "} {optionsFormatter(category, categorie_prodotti)}
                                                            {index < activity.bioCategories.length - 1 && ' / '}
                                                        </span>
                                                    ) : null

                                                ));
                                            }
                                        } else if (rowFunction.norm === 'GLOBAL_GAP') {
                                            return (
                                                <span key={activity.bioActivity}>
                                                    {activity.ggIfaOption}
                                                </span>
                                            );
                                        }
                                        return null;
                                    })}
                                </td>

                                <td className="text-neutral-700">
                                    {
                                        rowFunction.operativesAreas.map((area, index) => {
                                            const isLast = index === rowFunction.operativesAreas.length - 1;
                                            return <span key={area}>
                                                {optionsFormatter(area, regioniList)}
                                                {!isLast && ' / '}
                                            </span>;
                                        })
                                    }
                                </td>
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">
                                        <button onClick={() => {
                                            dispatch(setUpdateRequest(rowFunction))
                                            setOpenPopupEdit(true)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-5 flex align-items-center">
                                            <button onClick={() => {
                                                if (rowFunction.id) {
                                                    dispatch(DeleteFunction(rowFunction.id))
                                                }
                                            }
                                            }
                                            >
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {
                openPopup && (
                    <PopupForm title="Aggiungi Funzione" close={() => {
                        setOpenPopup(false)
                    }}>
                        <FunzioniPopupAdd close={() => setOpenPopup(false)} />
                    </PopupForm>
                )
            }
            {
                openPopupEdit && (
                    <PopupForm title="Modifica Funzione" close={() => {
                        setOpenPopupEdit(false)
                    }}>
                        <FunzioniPopupEdit close={() => setOpenPopupEdit(false)} />
                    </PopupForm>
                )
            }
        </div >
    )
}