import { Fragment } from 'react/jsx-runtime'
import '../styles.scss'
import Button from '../../../../../ui/molecules/button'
import { PlusOrange } from '../../../../../ui/icons/plusOrange'
import { useAppDispatch, useAppSelector } from '../../../../../lib/redux/hooks'
import { SelectCustom } from '../../../../../ui/molecules/select'
import { addDefaultTrainingActivity, addTrainingActivity, addUpdateDefaultTrainingActivity, setTrainingActivity, setTrainingActivityActivity, setTrainingActivityFrequency, setTrainingActivityNorms, setUpdateTrainingActivity, setUpdateTrainingActivityActivity, setUpdateTrainingActivityFrequency, setUpdateTrainingActivityNorms } from '../../slice'
import { normativa, optionsFormatter, OptionType, trainingActivityOptions } from '../../../../../utils'
import { useEffect, useState } from 'react'
import MultiSelectCheckbox from '../../../../../ui/molecules/multiSelectCheckbox'
import Input from '../../../../../ui/molecules/input'
import { TrashIcon } from '../../../../../ui/icons/trash'

export function ActivityAdd () {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const [selectedNorms, setSelectedNorms] = useState<OptionType[][]>([]);

    return (
        <div className='pb-5' style={{borderBottomWidth: 1}}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div className="text-left" style={{flex: '0.5'}}>
                    <span className="subtitle">Attività formativa</span>
                </div>
                <div style={{flex: '0.5', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                        size='sm' 
                        color='orange' 
                        variant='outline'
                        label='Aggiungi attività formativa' 
                        iconPosition='right' 
                        onClick={() => dispatch(addDefaultTrainingActivity())}
                        icon={<PlusOrange color='orange' size={20}/>} />
                </div>
            </div>
            {
                trainingState.trainingCreationRequest.trainingActivities.map((act,index) =>(
                    <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{ flex: '0.2' }}>
                            <div className="text-left">
                                <span className="input-label">Attività formativa</span>
                            </div>
                            <SelectCustom
                                // error={}
                                errorLabel="Nessuna attività specificata"
                                placeholder={'Seleziona attività formativa'}
                                options={trainingActivityOptions}
                                onChange={(value) => dispatch(setTrainingActivityActivity({data: value, index: index}))}
                                defaultValue={trainingState.trainingCreationRequest.trainingActivities[index].trainingActivity}
                                value={trainingState.trainingCreationRequest.trainingActivities[index].trainingActivity}
                                />
                        </div>
                        <div style={{ flex: '0.7' }}>
                            <div className="text-left">
                                <span className="input-label">Normativa di riferimento</span>
                            </div>
                            <MultiSelectCheckbox
                                onChange={(e) => {
                                    const norms = selectedNorms
                                    norms[index] = e.value                                    
                                    setSelectedNorms(norms)
                                    dispatch(setTrainingActivityNorms({data: e.value, index: index}))
                                }}
                                value={selectedNorms[index]}
                                option={normativa}
                                placeholder="Norma di riferimento"
                                selectAllLabel="Tutte"
                                />
                        </div>
                        <div style={{ flex: '0.15' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label={'Durata/Frequenza'}
                                placeholder="Specificare numero di ore"
                                value={trainingState.trainingCreationRequest.trainingActivities[index].frequency}
                                defaultValue={trainingState.trainingCreationRequest.trainingActivities[index].frequency}
                                onChangeText={(text) => {dispatch(setTrainingActivityFrequency({data: text, index: index}))}}
                                />
                        </div>
                        <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5}}>
                            <Button
                                size='md'
                                iconPosition='only'
                                icon={<TrashIcon color='#f00' size={20} />} 
                                variant='outline'
                                color='orange'
                                onClick={() => {
                                    const newActivities = trainingState.trainingCreationRequest.trainingActivities.filter((t,idx) => idx !== index)
                                    dispatch(setTrainingActivity(newActivities))
                                }}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export function ActivityEdit() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const [selectedNorms, setSelectedNorms] = useState<OptionType[][]>([]);
    const [activityValue, setActivityValue] = useState<string[]>([])

    useEffect(() => {
        const norms = trainingState.trainingUpdateRequest.trainingActivities.map(t => t.norms) as string[][]
        setSelectedNorms(norms as any)
        const activities = trainingState.trainingUpdateRequest.trainingActivities.map(t => t.trainingActivity)
        setActivityValue(activities)
    }, [])

    useEffect(() => {
        const norms = trainingState.trainingUpdateRequest.trainingActivities.map(t => t.norms) as string[][]
        setSelectedNorms(norms as any)
        const activities = trainingState.trainingUpdateRequest.trainingActivities.map(t => t.trainingActivity)
        setActivityValue(activities)
    }, [trainingState.trainingUpdateRequest.trainingActivities])

    return (
        <div className='pb-5' style={{borderBottomWidth: 1}}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div className="text-left" style={{flex: '0.5'}}>
                    <span className="subtitle">Attività formativa</span>
                </div>
                <div style={{flex: '0.5', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                        size='sm' 
                        color='orange' 
                        variant='outline'
                        label='Aggiungi attività formativa' 
                        iconPosition='right' 
                        onClick={() => dispatch(addUpdateDefaultTrainingActivity())}
                        icon={<PlusOrange color='orange' size={20}/>} />
                </div>
            </div>
            {
                trainingState.trainingUpdateRequest.trainingActivities.map((act,index) =>(
                    <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{ flex: '0.2' }}>
                            <div className="text-left">
                                <span className="input-label">Attività formativa</span>
                            </div>
                            <SelectCustom
                                // error={}
                                errorLabel="Nessuna attività specificata"
                                placeholder={'Seleziona attività formativa'}
                                options={trainingActivityOptions}
                                onChange={(value) => dispatch(setUpdateTrainingActivityActivity({data: value, index: index}))}
                                defaultValue={activityValue[index]}
                                value={activityValue[index]}
                                />
                        </div>
                        <div style={{ flex: '0.7' }}>
                            <div className="text-left">
                                <span className="input-label">Normativa di riferimento</span>
                            </div>
                            <MultiSelectCheckbox
                                onChange={(e) => {
                                    const norms = selectedNorms
                                    norms[index] = e.value                                    
                                    setSelectedNorms(norms)
                                    dispatch(setUpdateTrainingActivityNorms({data: e.value, index: index}))
                                }}
                                value={selectedNorms[index]}
                                option={normativa}
                                placeholder="Norma di riferimento"
                                selectAllLabel="Tutte"
                                />
                        </div>
                        <div style={{ flex: '0.15' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label={'Durata/Frequenza'}
                                placeholder="Specificare numero di ore"
                                value={trainingState.trainingUpdateRequest.trainingActivities[index].frequency}
                                defaultValue={trainingState.trainingUpdateRequest.trainingActivities[index].frequency}
                                onChangeText={(text) => {dispatch(setUpdateTrainingActivityFrequency({data: text, index: index}))}}
                                />
                        </div>
                        <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5}}>
                            <Button
                                size='md'
                                iconPosition='only'
                                icon={<TrashIcon color='#f00' size={20} />} 
                                variant='outline'
                                color='orange'
                                onClick={() => {
                                    const newActivities = trainingState.trainingUpdateRequest.trainingActivities.filter((t,idx) => idx !== index)
                                    dispatch(setUpdateTrainingActivity(newActivities))
                                }}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}