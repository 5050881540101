import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Layout } from "../../../../ui/layout"
import Button from "../../../../ui/molecules/button"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { TrainingPopupAdd } from "../popup/add"
import { GetAllTraining, resetTrainingCreationRequest, resetTrainingCreationStatus, resetTrainings, resetTrainingValidationStatus, TrainingCreation, TrainingValidation } from "../slice"
import { useNavigate } from "react-router-dom"

export function NewTraining() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const navigate = useNavigate()

    useEffect(() => {
        if(trainingState.trainingValidationStatus === 'successfully') {
            dispatch(resetTrainingValidationStatus())
            dispatch(TrainingCreation(trainingState.trainingCreationRequest))
        }
    }, [trainingState.trainingValidationStatus])

    useEffect(() => {
        if(trainingState.trainingCreationStatus === 'successfully') {
            dispatch(resetTrainingCreationRequest())
            dispatch(resetTrainingCreationStatus())
            dispatch(resetTrainings())
            dispatch(GetAllTraining(trainingState.getAllTrainingFilters))
            navigate('/formazione')
        }
    }, [trainingState.trainingCreationStatus])

    return (
        <Layout menuItem={MenuItems.GESTIONE_PERSONALE} breadcrumbItems={['Formazione dipendenti', 'Formazione', 'Nuova Formazione']}
            headerLabel="Nuova Formazione"
            rightBar={true}
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(TrainingValidation(trainingState.trainingCreationRequest))
                        }}
                    />
                </div>
            }
        >
            <div>
                <TrainingPopupAdd />
            </div>
        </Layout>
    )
}