import { IconProps } from "./dto";

export function PlusOrange(props: IconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_2501_3392)">
                <path d="M7.99992 5.3335V10.6668M5.33325 8.00016H10.6666M14.6666 8.00016C14.6666 11.6821 11.6818 14.6668 7.99992 14.6668C4.31802 14.6668 1.33325 11.6821 1.33325 8.00016C1.33325 4.31826 4.31802 1.3335 7.99992 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016Z" stroke="#FF941A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2501_3392">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}