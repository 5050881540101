import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GetAllTrainingsFiltersDTO, GetAllTrainingsResponseDTO, TrainingActivityDTO, TrainingDTO, TrainingErrorsDTO } from "./dataService/dto"
import { OptionType, PromiseStatuses } from "../../../utils"
import { NewTrainingService } from "./dataService/service"
import { FunctionsDTO } from "../boxFunzioni/dataService/dto"

interface FormazioneState {
    trainings: TrainingDTO[]
    PMResources: OptionType[]
    functions: FunctionsDTO[]

    // REQUEST
    trainingCreationRequest: TrainingDTO
    trainingUpdateRequest: TrainingDTO
    trainingIdToUpdate: string

    // FILTERS
    getAllTrainingFilters: GetAllTrainingsFiltersDTO

    // ERROR
    trainingErrors: TrainingErrorsDTO

    // PROTOCOL
    headquarter: string
    year: string
    number: string

    // STATUS
    trainingCreationStatus: PromiseStatuses
    trainingUpdateStatus: PromiseStatuses
    trainingDeleteStatus: PromiseStatuses
    getTrainingByIdStatus: PromiseStatuses
    getAllTrainingsStatus: PromiseStatuses
    trainingValidationStatus: PromiseStatuses

    // RESPONSE
    getTrainingByIdResponse?: TrainingDTO
    getAllTrainingsResponse?: GetAllTrainingsResponseDTO
}

const initialState: FormazioneState = {
    trainings: [],
    PMResources: [],
    functions: [],

    // REQUEST
    trainingCreationRequest: {
        starting: new Date(),
        ending: new Date(),
        credits: '',
        webinar: true,
        internalTeacher: [],
        internalTeacherFunction: [],
        externalTeacher: [],
        externalTeacherFunction: [],
        participants: [],
        topicsCovered: '',
        attendanceRegister: 'string',
        trainingActivities: []
    },
    trainingUpdateRequest: {
        starting: new Date(),
        ending: new Date(),
        credits: '',
        webinar: true,
        internalTeacher: [],
        internalTeacherFunction: [],
        externalTeacher: [],
        externalTeacherFunction: [],
        participants: [],
        topicsCovered: '',
        attendanceRegister: 'string',
        trainingActivities: []
    },
    trainingIdToUpdate: '',

    // FILTERS
    getAllTrainingFilters: {},

    // ERROR
    trainingErrors: {
        credits: false,
        headquarter: false,
        teacher: false,
        activities: false,
        internalTeacherFunction: false,
        externalTeacherFunction: false,
        participants: false,
        topicsCovered: false
    },

    // PROTOCOL
    headquarter: '',
    year: '',
    number: '',

    // STATUS
    trainingCreationStatus: 'idle',
    trainingUpdateStatus: 'idle',
    trainingDeleteStatus: 'idle',
    getTrainingByIdStatus: 'idle',
    getAllTrainingsStatus: 'idle',
    trainingValidationStatus: 'idle'
}

export const TrainingCreation = createAsyncThunk(
    'PM/Training/create',
    async (data: TrainingDTO, thunkApi): Promise<void> => {
        const functionsService = NewTrainingService()

        return functionsService.CreateTraining(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const TrainingUpdate = createAsyncThunk(
    'PM/Training/update',
    async (request: {data: TrainingDTO, id: string}, thunkApi): Promise<void> => {
        const functionsService = NewTrainingService()

        return functionsService.UpdateTraining(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const TrainingDelete = createAsyncThunk(
    'PM/Training/delete',
    async (id: string, thunkApi): Promise<void> => {
        const functionsService = NewTrainingService()

        return functionsService.DeleteTraining(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllTraining = createAsyncThunk(
    'PM/Training/getAll',
    async (filters: GetAllTrainingsFiltersDTO, thunkApi): Promise<GetAllTrainingsResponseDTO> => {
        const functionsService = NewTrainingService()

        return functionsService.GetAllTraining(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetTrainingById = createAsyncThunk(
    'PM/Training/getById',
    async (id: string, thunkApi): Promise<TrainingDTO> => {
        const functionsService = NewTrainingService()

        return functionsService.GetTrainingById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const TrainingValidation = createAsyncThunk(
    'PM/Training/Validate',
    async (request: TrainingDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetTrainingError())

        if(request.credits === '') {
            thunkApi.dispatch(setValidateTrainingCredits(true))
            isValid = false
        }

        if(!request.webinar && request.headQuarter === undefined) {
            thunkApi.dispatch(setValidateTrainingHeadquarter(true))
            isValid = false
        }

        if(request.externalTeacher.length < 1 && request.internalTeacher.length < 1) {
            thunkApi.dispatch(setValidateTrainingTeacher(true))
            isValid = false
        }

        if(request.externalTeacher.length > 0) {
            for(let i=0; i<request.externalTeacher.length; i++) {
                if(request.externalTeacherFunction[i] === undefined) {
                    thunkApi.dispatch(setValidateTrainingExternalTeacherFunction(true))
                    isValid = false
                    break;
                }
            }
        }

        if(request.internalTeacher.length > 0) {
            for(let i=0; i<request.internalTeacher.length; i++) {
                if(request.internalTeacherFunction[i] === undefined) {
                    thunkApi.dispatch(setValidateTrainingInternalTeacherFunction(true))
                    isValid = false
                    break;
                }
            }
        }

        if(request.participants.length < 1) {
            thunkApi.dispatch(setValidateTrainingParticipants(true))
            isValid = false
        }

        if(request.trainingActivities.length < 1) {
            thunkApi.dispatch(setValidateTrainingActivities(true))
            isValid = false
        }

        if(request.trainingActivities.length > 0) {
            for(let i=0; i<request.trainingActivities.length; i++) {
                if(request.trainingActivities[i].frequency === undefined &&
                    request.trainingActivities[i].trainingActivity === undefined
                ) {
                    thunkApi.dispatch(setValidateTrainingActivities(true))
                    isValid = false
                    break
                }
            }
        }

        if(request.topicsCovered === '') {
            thunkApi.dispatch(setValidateTrainingTopicsCovered(true))
            isValid = false
        }


        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

const PMFormazione = createSlice({
    name: 'PM/trainingSlice',
    initialState,
    reducers: {
        resetTrainings: (state) => {
            state.trainings = []
        },
        addTraining: (state, action) => {
            state.trainings.push(action.payload)
        },
        setTrainings: (state, action) => {
            state.trainings = action.payload
        },
        resetPMResources: (state) => {
            state.PMResources = []
        },
        addPMResource: (state, action) => {
            state.PMResources.push(action.payload)
        },
        setPMResources: (state, action) => {
            state.PMResources = action.payload
        },
        resetFunctions: (state, action) => {
            state.functions = []
        },
        addFunction: (state, action) => {
            state.functions.push(action.payload)
        },
        setFunctions: (state, action) => {
            state.functions = action.payload
        },

        // REQUEST (CREATION)
        setTrainingStartingDate: (state, action) => {
            state.trainingCreationRequest.starting = action.payload
        },
        setTrainingEndingDate: (state, action) => {
            state.trainingCreationRequest.ending = action.payload
        },
        setTrainingCredits: (state, action) => {
            state.trainingCreationRequest.credits = action.payload
        },
        setTrainingWebinar: (state, action) => {
            state.trainingCreationRequest.webinar = action.payload
        },
        setTrainingHeadquarter: (state, action) => {
            state.trainingCreationRequest.headQuarter = action.payload
        },
        setTrainingInternalTeacher: (state, action) => {
            state.trainingCreationRequest.internalTeacher = action.payload
        },
        setTrainingInternalTeacherFunction: (state, action) => {
            state.trainingCreationRequest.internalTeacherFunction = action.payload
        },
        setTrainingInternalTeacherByIndex: (state, action) => {
            state.trainingCreationRequest.internalTeacher[action.payload.index] = action.payload.data
        },
        setTrainingInternalTeacherFunctionByIndex: (state, action) => {
            state.trainingCreationRequest.internalTeacherFunction[action.payload.index]= action.payload.data
        },
        addTrainingDefaultInternalTeacher: (state) => {
            state.trainingCreationRequest.internalTeacher.push('')
        },
        addTrainingDefaultInternalTeacherFunction: (state) => {
            state.trainingCreationRequest.internalTeacherFunction.push('')
        },
        setTrainingExternalTeacher: (state, action) => {
            state.trainingCreationRequest.externalTeacher = action.payload
        },
        setTrainingExternalTeacherFunction: (state, action) => {
            state.trainingCreationRequest.externalTeacherFunction = action.payload
        },
        setTrainingExternalTeacherByIndex: (state, action) => {
            state.trainingCreationRequest.externalTeacher[action.payload.index] = action.payload.data
        },
        setTrainingExternalTeacherFunctionByIndex: (state, action) => {
            state.trainingCreationRequest.externalTeacherFunction[action.payload.index] = action.payload.data
        },
        addTrainingDefaultExternalTeacher: (state) => {
            state.trainingCreationRequest.externalTeacher.push('')
        },
        addTrainingDefaultExternalTeacherFunction: (state) => {
            state.trainingCreationRequest.externalTeacherFunction.push('')
        },
        setTrainingParticipants: (state, action) => {
            state.trainingCreationRequest.participants = action.payload
        },
        setTrainingAttendanceRegister: (state, action) => {
            state.trainingCreationRequest.attendanceRegister = action.payload
        },
        setTrainingTopicsCovered: (state, action) => {
            state.trainingCreationRequest.topicsCovered = action.payload
        },
        setTrainingOtherData: (state, action) => {
            state.trainingCreationRequest.otherData = action.payload
        },
        setTrainingActivity: (state, action) => {
            state.trainingCreationRequest.trainingActivities = action.payload
        },
        addTrainingActivity: (state, action) => {
            state.trainingCreationRequest.trainingActivities.push(action.payload)
        },
        addDefaultTrainingActivity: (state) => {
            state.trainingCreationRequest.trainingActivities.push({
                trainingActivity: '',
                norms: [],
                frequency: ''
            } as TrainingActivityDTO)
        },
        setTrainingActivityActivity: (state, action) => {
            state.trainingCreationRequest.trainingActivities[action.payload.index].trainingActivity = action.payload.data
        },
        setTrainingActivityNorms: (state, action) => {
            state.trainingCreationRequest.trainingActivities[action.payload.index].norms = action.payload.data
        },
        setTrainingActivityFrequency: (state, action) => {
            state.trainingCreationRequest.trainingActivities[action.payload.index].frequency = action.payload.data
        },
        resetTrainingCreationRequest: (state) => {
            state.trainingCreationRequest = {
                starting: new Date(),
                ending: new Date(),
                credits: '',
                webinar: true,
                headQuarter: undefined,
                internalTeacher: [],
                internalTeacherFunction: [],
                externalTeacher: [],
                externalTeacherFunction: [],
                participants: [],
                attendanceRegister: 'string',
                topicsCovered: '',
                otherData: undefined,
                trainingActivities: []
            }
        },

        // REQUEST (UPDATE)
        setTrainingIdToUpdate: (state, action) => {
            state.trainingIdToUpdate = action.payload
        },
        setUpdateTrainingStartingDate: (state, action) => {
            state.trainingUpdateRequest.starting = action.payload
        },
        setUpdateTrainingEndingDate: (state, action) => {
            state.trainingUpdateRequest.ending = action.payload
        },
        setUpdateTrainingCredits: (state, action) => {
            state.trainingUpdateRequest.credits = action.payload
        },
        setUpdateTrainingWebinar: (state, action) => {
            state.trainingUpdateRequest.webinar = action.payload
        },
        setUpdateTrainingHeadquarter: (state, action) => {
            state.trainingUpdateRequest.headQuarter = action.payload
        },
        setUpdateTrainingInternalTeacher: (state, action) => {
            state.trainingUpdateRequest.internalTeacher = action.payload
        },
        setUpdateTrainingInternalTeacherFunction: (state, action) => {
            state.trainingUpdateRequest.internalTeacherFunction = action.payload
        },
        setUpdateTrainingInternalTeacherByIndex: (state, action) => {
            state.trainingUpdateRequest.internalTeacher[action.payload.index] = action.payload.data
        },
        setUpdateTrainingInternalTeacherFunctionByIndex: (state, action) => {
            state.trainingUpdateRequest.internalTeacherFunction[action.payload.index]= action.payload.data
        },
        addUpdateTrainingDefaultInternalTeacher: (state) => {
            state.trainingUpdateRequest.internalTeacher.push('')
        },
        addUpdateTrainingDefaultInternalTeacherFunction: (state) => {
            state.trainingUpdateRequest.internalTeacherFunction.push('')
        },
        setUpdateTrainingExternalTeacher: (state, action) => {
            state.trainingUpdateRequest.externalTeacher = action.payload
        },
        setUpdateTrainingExternalTeacherFunction: (state, action) => {
            state.trainingUpdateRequest.externalTeacherFunction = action.payload
        },
        setUpdateTrainingExternalTeacherByIndex: (state, action) => {
            state.trainingUpdateRequest.externalTeacher[action.payload.index] = action.payload.data
        },
        setUpdateTrainingExternalTeacherFunctionByIndex: (state, action) => {
            state.trainingUpdateRequest.externalTeacherFunction[action.payload.index] = action.payload.data
        },
        addUpdateTrainingDefaultExternalTeacher: (state) => {
            state.trainingUpdateRequest.externalTeacher.push('')
        },
        addUpdateTrainingDefaultExternalTeacherFunction: (state) => {
            state.trainingUpdateRequest.externalTeacherFunction.push('')
        },
        setUpdateTrainingParticipants: (state, action) => {
            state.trainingUpdateRequest.participants = action.payload
        },
        setUpdateTrainingAttendanceRegister: (state, action) => {
            state.trainingUpdateRequest.attendanceRegister = action.payload
        },
        setUpdateTrainingTopicsCovered: (state, action) => {
            state.trainingUpdateRequest.topicsCovered = action.payload
        },
        setUpdateTrainingOtherData: (state, action) => {
            state.trainingUpdateRequest.otherData = action.payload
        },
        setUpdateTrainingActivity: (state, action) => {
            state.trainingUpdateRequest.trainingActivities = action.payload
        },
        addUpdateTrainingActivity: (state, action) => {
            state.trainingUpdateRequest.trainingActivities.push(action.payload)
        },
        addUpdateDefaultTrainingActivity: (state) => {
            state.trainingUpdateRequest.trainingActivities.push({
                trainingActivity: '',
                norms: [],
                frequency: ''
            } as TrainingActivityDTO)
        },
        setUpdateTrainingActivityActivity: (state, action) => {
            state.trainingUpdateRequest.trainingActivities[action.payload.index].trainingActivity = action.payload.data
        },
        setUpdateTrainingActivityNorms: (state, action) => {
            state.trainingUpdateRequest.trainingActivities[action.payload.index].norms = action.payload.data
        },
        setUpdateTrainingActivityFrequency: (state, action) => {
            state.trainingUpdateRequest.trainingActivities[action.payload.index].frequency = action.payload.data
        },
        resetTrainingUpdateRequest: (state) => {
            state.trainingUpdateRequest = {
                starting: new Date(),
                ending: new Date(),
                credits: '',
                webinar: true,
                headQuarter: undefined,
                internalTeacher: [],
                internalTeacherFunction: [],
                externalTeacher: [],
                externalTeacherFunction: [],
                participants: [],
                attendanceRegister: 'string',
                topicsCovered: '',
                otherData: undefined,
                trainingActivities: []
            }
        },

        // FILTERS
        setGetAllTrainingFiltersDate: (state, action) => {
            state.getAllTrainingFilters.date = action.payload === '' ? undefined : action.payload
        },
        setGetAllTrainingFiltersItemPerPage: (state, action) => {
            state.getAllTrainingFilters.itemsPerPage = action.payload === '' ? undefined : action.payload
        },
        setGetAllTrainingFiltersNorm: (state, action) => {
            state.getAllTrainingFilters.norm = action.payload === '' ? undefined : action.payload
        },
        setGetAllTrainingFiltersOrder: (state, action) => {
            state.getAllTrainingFilters.order = action.payload === '' ? undefined : action.payload
        },
        setGetAllTrainingFiltersPage: (state, action) => {
            state.getAllTrainingFilters.page = action.payload === '' ? undefined : action.payload
        },
        setGetAllTrainingFiltersParticipant: (state, action) => {
            state.getAllTrainingFilters.participant = action.payload === '' ? undefined : action.payload
        },
        setGetAllTrainingFiltersSort: (state, action) => {
            state.getAllTrainingFilters.sort = action.payload === '' ? undefined : action.payload
        },
        setGetAllTrainingFiltersType: (state, action) => {
            state.getAllTrainingFilters.type = action.payload === '' ? undefined : action.payload
        },
        resetGetAllTrainingFilters: (state) => {
            state.getAllTrainingFilters = {}
        },

        // ERROR
        setValidateTrainingCredits: (state, action) => {
            state.trainingErrors.credits = action.payload
        },
        setValidateTrainingHeadquarter: (state, action) => {
            state.trainingErrors.headquarter = action.payload
        },
        setValidateTrainingTeacher: (state, action) => {
            state.trainingErrors.teacher = action.payload
        },
        setValidateTrainingInternalTeacherFunction: (state, action) => {
            state.trainingErrors.internalTeacherFunction = action.payload
        },
        setValidateTrainingExternalTeacherFunction: (state, action) => {
            state.trainingErrors.externalTeacherFunction = action.payload
        },
        setValidateTrainingParticipants: (state, action) => {
            state.trainingErrors.participants = action.payload
        },
        setValidateTrainingTopicsCovered: (state, action) => {
            state.trainingErrors.topicsCovered = action.payload
        },
        setValidateTrainingActivities: (state, action) => {
            state.trainingErrors.activities = action.payload
        },
        resetTrainingError: (state) => {
            state.trainingErrors = {
                credits: false,
                headquarter: false,
                teacher: false,
                activities: false,
                internalTeacherFunction: false,
                externalTeacherFunction: false,
                participants: false,
                topicsCovered: false
            }
        },

        // PROTOCOL
        setTrainingProtocolHeadquarter: (state, action) => {
            state.headquarter = action.payload
        },
        setTrainingProtocolYear: (state, action) => {
            state.year = action.payload
        },
        setTrainingProtocolNumber: (state, action) => {
            state.number = action.payload
        },



        // STATUS
        resetTrainingCreationStatus: (state) => {
            state.trainingCreationStatus = 'idle'
        },
        resetTrainingUpdateStatus: (state) => {
            state.trainingUpdateStatus = 'idle'
        },
        resetTrainingDeleteStatus: (state) => {
            state.trainingDeleteStatus = 'idle'
        },
        resetGetTrainingByIdStatus: (state) => {
            state.getTrainingByIdStatus = 'idle'
        },
        resetGetAllTrainingsStatus: (state) => {
            state.getAllTrainingsStatus = 'idle'
        },
        resetTrainingValidationStatus: (state) => {
            state.trainingValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // CREATION
            .addCase(TrainingCreation.pending, (state) => {
                state.trainingCreationStatus = 'loading'
            })
            .addCase(TrainingCreation.fulfilled, (state, action) => {
                state.trainingCreationStatus = 'successfully'
            })
            .addCase(TrainingCreation.rejected, (state) => {
                state.trainingCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(TrainingUpdate.pending, (state) => {
                state.trainingUpdateStatus = 'loading'
            })
            .addCase(TrainingUpdate.fulfilled, (state, action) => {
                state.trainingUpdateStatus = 'successfully'
            })
            .addCase(TrainingUpdate.rejected, (state) => {
                state.trainingUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(TrainingDelete.pending, (state) => {
                state.trainingDeleteStatus = 'loading'
            })
            .addCase(TrainingDelete.fulfilled, (state, action) => {
                state.trainingDeleteStatus = 'successfully'
            })
            .addCase(TrainingDelete.rejected, (state) => {
                state.trainingDeleteStatus = 'failed'
            })

            // VALIDATION
            .addCase(TrainingValidation.pending, (state) => {
                state.trainingValidationStatus = 'loading'
            })
            .addCase(TrainingValidation.fulfilled, (state, action) => {
                state.trainingValidationStatus = 'successfully'
            })
            .addCase(TrainingValidation.rejected, (state) => {
                state.trainingValidationStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllTraining.pending, (state) => {
                state.getAllTrainingsStatus = 'loading'
            })
            .addCase(GetAllTraining.fulfilled, (state, action) => {
                state.getAllTrainingsStatus = 'successfully'
                state.getAllTrainingsResponse = action.payload
            })
            .addCase(GetAllTraining.rejected, (state) => {
                state.getAllTrainingsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetTrainingById.pending, (state) => {
                state.getTrainingByIdStatus = 'loading'
            })
            .addCase(GetTrainingById.fulfilled, (state, action) => {
                state.getTrainingByIdStatus = 'successfully'
                state.getTrainingByIdResponse = action.payload
            })
            .addCase(GetTrainingById.rejected, (state) => {
                state.getTrainingByIdStatus = 'failed'
            })
    },
})

export const {
    resetTrainings,
    addTraining,
    setTrainings,
    resetPMResources,
    addPMResource,
    setPMResources,
    resetFunctions,
    addFunction,
    setFunctions,

    // REQUEST (CREATION)
    setTrainingAttendanceRegister,
    setTrainingEndingDate,
    setTrainingExternalTeacher,
    setTrainingExternalTeacherFunction,
    setTrainingExternalTeacherByIndex,
    setTrainingExternalTeacherFunctionByIndex,
    addTrainingDefaultExternalTeacher,
    addTrainingDefaultExternalTeacherFunction,
    setTrainingCredits,
    setTrainingHeadquarter,
    setTrainingInternalTeacher,
    setTrainingInternalTeacherFunction,
    setTrainingInternalTeacherByIndex,
    setTrainingInternalTeacherFunctionByIndex,
    addTrainingDefaultInternalTeacher,
    addTrainingDefaultInternalTeacherFunction,
    setTrainingOtherData,
    setTrainingParticipants,
    setTrainingStartingDate,
    setTrainingTopicsCovered,
    setTrainingWebinar,
    setTrainingActivityActivity,
    addTrainingActivity,
    addDefaultTrainingActivity,
    setTrainingActivityFrequency,
    setTrainingActivityNorms,
    setTrainingActivity,
    resetTrainingCreationRequest,
    
    // REQUEST (UPDATE)
    setTrainingIdToUpdate,
    setUpdateTrainingAttendanceRegister,
    setUpdateTrainingEndingDate,
    setUpdateTrainingExternalTeacher,
    setUpdateTrainingExternalTeacherFunction,
    setUpdateTrainingCredits,
    setUpdateTrainingHeadquarter,
    setUpdateTrainingInternalTeacher,
    setUpdateTrainingInternalTeacherFunction,
    setUpdateTrainingExternalTeacherByIndex,
    setUpdateTrainingExternalTeacherFunctionByIndex,
    addUpdateTrainingDefaultExternalTeacher,
    addUpdateTrainingDefaultExternalTeacherFunction,
    setUpdateTrainingInternalTeacherByIndex,
    setUpdateTrainingInternalTeacherFunctionByIndex,
    addUpdateTrainingDefaultInternalTeacher,
    addUpdateTrainingDefaultInternalTeacherFunction,
    setUpdateTrainingOtherData,
    setUpdateTrainingParticipants,
    setUpdateTrainingStartingDate,
    setUpdateTrainingTopicsCovered,
    setUpdateTrainingWebinar,
    setUpdateTrainingActivityActivity,
    addUpdateDefaultTrainingActivity,
    addUpdateTrainingActivity,
    setUpdateTrainingActivityFrequency,
    setUpdateTrainingActivityNorms,
    setUpdateTrainingActivity,
    resetTrainingUpdateRequest,

    // FILTERS
    setGetAllTrainingFiltersDate,
    setGetAllTrainingFiltersItemPerPage,
    setGetAllTrainingFiltersNorm,
    setGetAllTrainingFiltersOrder,
    setGetAllTrainingFiltersPage,
    setGetAllTrainingFiltersParticipant,
    setGetAllTrainingFiltersSort,
    setGetAllTrainingFiltersType,
    resetGetAllTrainingFilters,

    // ERROR
    setValidateTrainingCredits,
    setValidateTrainingTeacher,
    setValidateTrainingExternalTeacherFunction,
    setValidateTrainingHeadquarter,
    setValidateTrainingInternalTeacherFunction,
    setValidateTrainingParticipants,
    setValidateTrainingTopicsCovered,
    setValidateTrainingActivities,
    resetTrainingError,

    // PROTOCOL
    setTrainingProtocolHeadquarter,
    setTrainingProtocolNumber,
    setTrainingProtocolYear,

    // STATUS
    resetGetTrainingByIdStatus,
    resetTrainingCreationStatus,
    resetTrainingDeleteStatus,
    resetTrainingUpdateStatus,
    resetGetAllTrainingsStatus,
    resetTrainingValidationStatus

} = PMFormazione.actions

export default PMFormazione.reducer