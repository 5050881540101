import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { headquartersOptions, OptionType, WebinarOptions, yearOptions } from "../../../../utils"
import { addPMResource, resetPMResources, setTrainingOtherData, setTrainingProtocolHeadquarter, setTrainingProtocolNumber, setTrainingProtocolYear, setTrainingWebinar, setUpdateTrainingCredits, setUpdateTrainingEndingDate, setUpdateTrainingHeadquarter, setUpdateTrainingOtherData, setUpdateTrainingParticipants, setUpdateTrainingStartingDate, setUpdateTrainingTopicsCovered, setUpdateTrainingWebinar } from "../slice"
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus } from "../../slice"
import { GetByIdAnagraphic, resetGetByIdStatus } from "../../boxAnagrafica/slice"
import RadioButtonGroup from "../../../../ui/molecules/RadioButtonGroup"
import { SelectCustom } from "../../../../ui/molecules/select"
import DatePicker from "react-datepicker"
import Input from "../../../../ui/molecules/input"
import { CalendarIcon } from "../../../../ui/icons/calendar"
import { format } from "date-fns"
import MultiSelectCheckbox from "../../../../ui/molecules/multiSelectCheckbox"
import TextArea from "../../../../ui/molecules/textArea"
import { ActivityEdit } from "./components/activity"
import { InternalTeachersEdit } from "./components/intTeachers"
import { ExternalTeachersEdit } from "./components/extTeachers"

export function TrainingPopupEdit() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const PMState = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)
    const [selectedOption, setSelectedOption] = useState<WebinarOptions | null>(WebinarOptions.WEBINAR );
    const [participants, setParticipants] = useState<OptionType[]>([])

    useEffect(() => {
        dispatch(resetPMResources())
        dispatch(GetAllPersonalManagement())
        setSelectedOption(trainingState.trainingUpdateRequest.webinar ? 
                            WebinarOptions.WEBINAR : WebinarOptions.SEDE)
        setParticipants(trainingState.trainingUpdateRequest.participants as any)
    }, [])

    useEffect(() => {
        if(PMState.getAllPersonalManagementResourcesStatus === 'successfully' &&
            PMState.getAllPersonalManagementResourcesResponse !== undefined
        ) {
            dispatch(resetGetAllPersonalManagementResourcesStatus())
            const data = PMState.getAllPersonalManagementResourcesResponse.data
            for(let i=0; i<data.length; i++) {
                dispatch(GetByIdAnagraphic(String(data[i].anagraphicId)))
            }
        }
    }, [PMState.getAllPersonalManagementResourcesStatus])

    useEffect(() => {
        if(anagraficaState.GetByIdAnagraphicStatus === 'successfully' &&
            anagraficaState.getByIdAnagraphicRequest !== undefined
        ) {
            dispatch(resetGetByIdStatus('idle'))
            const data = anagraficaState.getByIdAnagraphicRequest
            dispatch(addPMResource({value: data.personalManagementId, label: `${data.name} ${data.surname}`}))
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])

    const handleOptionChange = (value: WebinarOptions) => {
        setSelectedOption(value);
        if (value === 'WEBINAR') {
            dispatch(setUpdateTrainingWebinar(true))
        }
        else {
            dispatch(setUpdateTrainingWebinar(false))
        }
    };

    return (
        <div className="popup">

            {/* FIRST ROW */}
            <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>

                <div style={{ flex: '0.15' }}>
                    <RadioButtonGroup
                        name={'webinar'}
                        options={Object.values(WebinarOptions)}
                        selectedValue={selectedOption}
                        onChange={handleOptionChange}
                        optionLabels={{
                            [WebinarOptions.WEBINAR]: 'Webinar',
                            [WebinarOptions.SEDE]: 'In sede',
                        }}
                    />
                </div>

                <div style={{ flex: '0.85' }}>
                    <SelectCustom
                        error={trainingState.trainingErrors.headquarter}
                        errorLabel="Sede mancante"
                        placeholder={'Specifica sede'}
                        disabled={selectedOption === WebinarOptions.WEBINAR}
                        options={headquartersOptions}
                        onChange={(value) => dispatch(setUpdateTrainingHeadquarter(value))}
                        defaultValue={trainingState.trainingUpdateRequest.headQuarter}
                        value={trainingState.trainingUpdateRequest.headQuarter}
                        />
                </div>

            </div>

            {/* SECOND ROW */}
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>

                <div style={{ flex: '0.4' }}>
                    <div className="text-left">
                        <span className="input-label">Dal</span>
                    </div>
                    <DatePicker locale="it" dateFormat="d/MM/yyyy"
                        selected={trainingState.trainingUpdateRequest.starting}
                        customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                        onChange={
                            (date) => {
                                if (date !== null) {
                                    dispatch(setUpdateTrainingStartingDate(format(new Date(date), 'yyyy-MM-dd')))
                                }
                            }
                        }
                    />
                </div>

                <div style={{ flex: '0.4' }}>
                    <div className="text-left">
                        <span className="input-label">Al</span>
                    </div>
                    <DatePicker locale="it" dateFormat="d/MM/yyyy"
                        selected={trainingState.trainingUpdateRequest.ending}
                        customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                        onChange={
                            (date) => {
                                if (date !== null) {
                                    dispatch(setUpdateTrainingEndingDate(format(new Date(date), 'yyyy-MM-dd')))
                                }
                            }
                        }
                    />
                </div>

                <div style={{ flex: '0.2' }}>
                    <Input
                        error={trainingState.trainingErrors.credits}
                        supportingText={trainingState.trainingErrors.credits ? 'Crediti mancanti' : undefined}
                        label={'Crediti'}
                        placeholder="Specificare numero crediti"
                        value={trainingState.trainingUpdateRequest.credits}
                        defaultValue={trainingState.trainingUpdateRequest.credits}
                        onChangeText={(text) => {dispatch(setUpdateTrainingCredits(text))}}
                        />
                </div>
                
            </div>

            {
                trainingState.trainingErrors.activities && (
                    <div className="gap-[8px] text-left bg-red-300" style={{ margin: '12px', borderRadius: '8px' }}>
                        <span className="text-red-700 fieldsetTitle" style={{ padding: '15px 12px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Attività o campi dell'attività mancanti!
                        </span>
                    </div>
                )
            }

            {/* THIRD ROW (ATTIVITA FORMATIVA) */}
            <ActivityEdit />

            {
                trainingState.trainingErrors.teacher && (
                    <div className="gap-[8px] text-left bg-red-300" style={{ margin: '12px', borderRadius: '8px' }}>
                        <span className="text-red-700 fieldsetTitle" style={{ padding: '15px 12px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Deve essere presente almeno un insegnante, che sia interno od esterno.
                        </span>
                    </div>
                )
            }
            {/* FOURTH ROW (DOCENTI INTERNI) */}
            <InternalTeachersEdit />

            {/* FIFTH ROW (DOCENTI ESTERNI) */}
            <ExternalTeachersEdit />

            {/* SIXTH ROW (PARTECIPANTI) */}
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1}}>
                <div style={{flex: '1'}}>
                    <div className="text-left pb-1">
                        <span className="input-label">Partecipanti</span>
                    </div>
                    <MultiSelectCheckbox
                        error={trainingState.trainingErrors.participants}
                        errorLabel="Non ci sono partecipanti!"
                        onChange={(e) => {
                            setParticipants(e.value)
                            dispatch(setUpdateTrainingParticipants(e.value))
                        }}
                        value={participants}
                        option={trainingState.PMResources}
                        placeholder="Partecipanti"
                        selectAllLabel="Tutti"
                        />
                </div>
            </div>

            {/* SEVENTH ROW (PROTOCOLLO) */}
            <div className="text-left pt-5" style={{flex: '0.5'}}>
                <span className="subtitle">{`Registro Presenze - CDX RPA`}</span>
            </div>
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1}}>
                <div style={{ flex: '0.33' }}>
                    <div className="text-left">
                        <span className="input-label">Ricerca e salva protocollo</span>
                    </div>
                    <SelectCustom
                        placeholder={'Seleziona sede...'}
                        options={headquartersOptions}
                        onChange={(value) => {setTrainingProtocolHeadquarter(value)}}
                        defaultValue={trainingState.headquarter}
                        value={trainingState.headquarter}
                        />
                </div>

                <div style={{ flex: '0.33' }}>
                    <div className="text-left">
                        <span className="input-label">Anno</span>
                    </div>
                    <SelectCustom
                        placeholder={'Anno...'}
                        options={yearOptions}
                        onChange={(value) => dispatch(setTrainingProtocolYear(value))}
                        defaultValue={trainingState.year}
                        value={trainingState.year}
                        />
                </div>

                <div style={{ flex: '0.33' }}>
                    <Input
                        label={'Numero'}
                        placeholder="Specificare numero..."
                        value={trainingState.number}
                        defaultValue={trainingState.number}
                        onChangeText={(text) => {dispatch(setTrainingProtocolNumber(text))}}
                        />
                </div>
            </div>

            {/* EIGHTH ROW (ARGOMENTI TRATTATI) */}
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1}}>
                <div style={{ flex: '1' }}>
                    <div className="text-left">
                        <span className="input-label">Argomenti trattati</span>
                    </div>
                    <TextArea
                        error={trainingState.trainingErrors.topicsCovered}
                        supportingText={trainingState.trainingErrors.topicsCovered ? 'Non è stato inserito alcun argomento' : undefined}
                        defaultValue={trainingState.trainingUpdateRequest.topicsCovered}
                        value={trainingState.trainingUpdateRequest.topicsCovered} 
                        placeholder='Descrivi in dettaglio gli argomenti trattati' 
                        onChangeText={(value) => dispatch(setUpdateTrainingTopicsCovered(value))} />
                </div>
            </div>

            {/* NINETH ROW (ALTRI DATI) */}
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div style={{ flex: '1' }}>
                    <div className="text-left">
                        <span className="input-label">Altri dati</span>
                    </div>
                    <TextArea 
                        onChangeText={(value) => dispatch(setUpdateTrainingOtherData(value))}
                        defaultValue={trainingState.trainingUpdateRequest.otherData}
                        value={trainingState.trainingUpdateRequest.otherData} />
                </div>
            </div>

        </div>
    )
}