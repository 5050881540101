import { useEffect, useState } from "react";
import { ListItem } from "../../../ui/organisms/listItem";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus, setPMJobs } from "../slice";
import Button from "../../../ui/molecules/button";
import { PopupForm } from "../../../ui/organisms/popupForm";
import MultiSelectCheckbox from "../../../ui/molecules/multiSelectCheckbox";
import { rolesOptions } from "../../../utils";

export function BoxFormazioneSingle() {
    const dispatch = useAppDispatch()
    const requirementState = useAppSelector(state => state.PMRequirement)
    const PMState = useAppSelector(state => state.personalManagement)


    return (
        <div style={{ padding: '24px' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <span id="title-eval-tab">Formazione</span>
                    
                </div>
                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px' }}>Dal/Al</th>
                            <th>Crediti</th>
                            <th>Tipo di formazione</th>
                            <th>Argomenti Trattati</th>
                            <th>Altri Dati</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>

                </table>


            </div>
        </div>
    )
}