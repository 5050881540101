import axios from "axios";
import { GetAllPersonalManagementDTO, PersonalManagementDTO, PersonalManagementUpdateDTO } from "./dto";
import { PersonalManagementService } from "./service";
import { keycloak } from "../../../lib/keycloak";

export class PersonalManagementServiceImpl implements PersonalManagementService {

    public GetAllPersonalManagementResources(): Promise<GetAllPersonalManagementDTO> {
        return axios.get("/api/personal-management", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetByIdPersonalManagementResources(id: string): Promise<PersonalManagementDTO> {
        return axios.get("/api/personal-management/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public CreatePersonalManagementResource(): Promise<string> {
        return axios.post("/api/personal-management",
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdatePersonalManagementResource(data: PersonalManagementUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/personal-management/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
}