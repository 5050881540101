import { Fragment } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { useEffect, useState } from "react";
import { SelectCustom } from "../../../../ui/molecules/select";
import { RowActivityMultiSelect, attivita_bio, categorie_prodotti } from "../../../../utils";
import DatePicker from "react-datepicker";
import Input from "../../../../ui/molecules/input";
import { CalendarIcon } from "../../../../ui/icons/calendar";
import { TrashIcon } from "../../../../ui/icons/trash";
import { PlusOrange } from "../../../../ui/icons/plusOrange";
import MultiSelectCheckbox from "../../../../ui/molecules/multiSelectCheckbox";
import { setRowsActivityBio, setUpdateRowsActivityBio } from "../slice";
import { format } from "date-fns";

interface Props {
    editRows?: boolean;
}

export function RowActivity(props: Props) {
    const dispatch = useAppDispatch();
    const functionsState = useAppSelector((state) => state.PMFunctions)
    const [selectedCategory, setSelectedCategory] = useState<{ label: string; value: string }[]>([]);

    const [rows, setRows] = useState<RowActivityMultiSelect[]>([
        { bioActivity: "", bioCategories: [], startingActivity: "", endingActivity: "" },
    ]);

    useEffect(() => {
        if (functionsState.functionUpdateRequest.functionActivities) {
            const activities = functionsState.functionUpdateRequest.functionActivities as any as RowActivityMultiSelect[];
            setRows([...activities]);
            dispatch(setUpdateRowsActivityBio(activities));
        }
    }, [functionsState.functionUpdateRequest.functionActivities]);

    const addRow = () => {
        setRows([...rows, { bioActivity: "", bioCategories: [], startingActivity: "", endingActivity: "" }]);
    };

    const removeRow = (index: number) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        if (props.editRows) {
            dispatch(setUpdateRowsActivityBio(updatedRows));
        } else {
            dispatch(setRowsActivityBio(updatedRows));
        }
    };

    const handleChange = (
        index: number,
        field: keyof RowActivityMultiSelect,
        value: string | { label: string; value: string }[]
    ) => {
        // copy array fix for edit
        const updatedRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                return {
                    ...row, // copy row
                    [field]: value, // update value
                };
            }
            return row;
        });

        setRows(updatedRows);

        const rowsToDispatch = updatedRows.map((row) => ({
            ...row,
            bioCategories: row.bioCategories?.map((category) => category),
        }));

        if (props.editRows) {
            dispatch(setUpdateRowsActivityBio(rowsToDispatch));
        } else {
            dispatch(setRowsActivityBio(rowsToDispatch));
        }
    };

    return (
        <Fragment>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px" }}>
                <p className="popupSectionTitle">Attività</p>
                <button onClick={addRow} style={{ color: "#FF941A", display: "flex", alignItems: "center", gap: "8px" }}>
                    Aggiungi attività <PlusOrange color={"#FF941A"} size={20} />
                </button>
            </div>

            {rows.map((row, index) => (
                <>
                    <div className="mt-4" key={index} style={{ display: "flex", flexWrap: "wrap", gap: "16px", flexDirection: "row" }}>
                        <div style={{ flex: 0.33 }}>
                            <div className="text-left">
                                <span className="input-label">Attività</span>
                            </div>
                            <SelectCustom
                                placeholder="Attività"
                                options={attivita_bio}
                                defaultValue={row.bioActivity}
                                value={row.bioActivity}
                                onChange={(e) => { if (e) { handleChange(index, "bioActivity", e); } }}
                            />
                        </div>



                        <div style={{ flex: 0.33 }}>
                            <div className="text-left">
                                <span className="input-label">Data Inizio</span>
                            </div>
                            <DatePicker
                                locale="it"
                                dateFormat="d/MM/yyyy"
                                selected={row.startingActivity ? new Date(row.startingActivity) : null}
                                customInput={<Input startIcon={<CalendarIcon size={20} color={"blue"} />} />}
                                onChange={(date) => {
                                    if (date !== null) {
                                        handleChange(index, "startingActivity", format(new Date(date), "yyyy-MM-dd"));
                                    }
                                }}
                            />
                        </div>

                        <div style={{ flex: 0.33 }}>
                            <div className="text-left">
                                <span className="input-label">Data Fine</span>
                            </div>
                            <DatePicker
                                locale="it"
                                dateFormat="d/MM/yyyy"
                                selected={row.endingActivity ? new Date(row.endingActivity) : null}
                                customInput={<Input startIcon={<CalendarIcon size={20} color={"blue"} />} />}
                                onChange={(date) => {
                                    if (date !== null) {
                                        handleChange(index, "endingActivity", format(new Date(date), "yyyy-MM-dd"));
                                    }
                                }}
                            />
                        </div>
                        <div style={{ flex: 0.25, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button onClick={() => removeRow(index)} style={{ color: "red", marginTop: '18px' }}>
                                <TrashIcon color="#8383AD" size={20} />
                            </button>
                        </div>


                    </div>
                    <div className="mt-4" key={index} style={{ display: "flex", flexWrap: "wrap", gap: "16px", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                            <div className="text-left">
                                <span className="input-label">Categorie</span>
                            </div>
                            <MultiSelectCheckbox
                                onChange={(e) => {
                                    setSelectedCategory(e.value);
                                    handleChange(index, "bioCategories", e.value);
                                }}
                                value={row.bioCategories!}
                                option={categorie_prodotti}
                                placeholder="Categoria prodotti"
                                selectAllLabel="Tutte"
                            />
                        </div>
                    </div>
                </>
            ))}
        </Fragment>
    );
}