import { Router } from "@remix-run/router"
import { useEffect, useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { DashboardView } from "../../pages/dashboard"
import { Layout } from "../../ui/layout"
import { keycloak } from "../keycloak"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { SpinnerComponent } from "../spinner"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { setMenuItems } from "../../ui/layout/slice"
import { Users } from "../../pages/users/users"
import { User } from "../../pages/users/user"
import { EditUser } from "../../pages/users/edit-user"
import { Clienti } from "../../pages/clienti"
import { NewCliente } from "../../pages/clienti/newCliente"
import { TecnicoAziendale } from "../../pages/businessTechnician"
import { EditCustomer } from "../../pages/clienti/editCustomer"
import { AddNewTech } from "../../pages/businessTechnician/addNew"
import { EditTech } from "../../pages/businessTechnician/edit"
import { GestionePersonale } from "../../pages/gestionePersonale"
import { NewStaff } from "../../pages/gestionePersonale/NewStaff"
import { EditStaff } from "../../pages/gestionePersonale/editStaff"
import { Formazione } from "../../pages/gestionePersonale/formazione"
import { NewTraining } from "../../pages/gestionePersonale/boxFormazione/newFormazione"
import { EditTraining } from "../../pages/gestionePersonale/boxFormazione/editFormazione"

const adminRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/users',
        element: (<Users />)
    },
    {
        path: '/clienti',
        element: (<Clienti />)
    },
    {
        path: '/cliente',
        element: (<NewCliente />)
    },
    {
        path: '/tecnicoAziendale',
        element: (<AddNewTech />)
    },
    {
        path: '/editTecnicoAziendale/:id',
        element: (<EditTech />)
    },
    {
        path: '/tecnico-aziendale',
        element: (<TecnicoAziendale />)
    },
    {
        path: '/user',
        element: (<User />)
    },
    {
        path: '/edit-user/:id',
        element: (<EditUser />)
    },
    {
        path: '/edit-customer/:id',
        element: (<EditCustomer />)
    },
    {
        path: '/gestionePersonale',
        element: (<GestionePersonale />)
    },
    {
        path: '/formazione',
        element: (<Formazione />)
    },
    {
        path: '/nuovoFormazione',
        element: (<NewTraining />)
    },
    {
        path: '/editFormazione',
        element: (<EditTraining />)
    },
    {
        path: '/nuovoPersonale',
        element: (<NewStaff />)
    },
    {
        path: '/editPm/:id',
        element: (<EditStaff />)
    },
    
])

const userRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    }
])

const ispettoriRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    }
])


const clientiRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    }
])


export function KeycloakRouter() {
    const roles = useAppSelector(state => state.keycloak.userRoles)

    const dispatch = useAppDispatch()

    const [router, setRouter] = useState<Router | undefined>(undefined)

    useEffect(() => {
        if (roles !== undefined && roles.length > 0 && keycloak.token !== undefined) {
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('Amm') || keycloak.hasRealmRole('FST') || keycloak.hasRealmRole('RST')) {
                setRouter(adminRouter)
                dispatch(setMenuItems([
                    { item: MenuItems.DASHBOARD, section: 1 },
                    { item: MenuItems.USERS, section: 1 },
                    { item: MenuItems.CLIENTI, section: 1 },
                    { item: MenuItems.TECNICO_AZIENDALE, section: 1 },
                    { item: MenuItems.GESTIONE_PERSONALE, section: 2 },
                ]))
            } 
            else {
                return
            }
        }
    }, [roles, keycloak.token])

    if (router === undefined) {
        return (<div>loading</div>)
    }

    return (
        <RouterProvider router={router} />
    );
}