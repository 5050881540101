import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React from "react";
import './style.scss';

import { colors } from '../../colors';
import { CheckedIcon } from '../../icons/checkedIcon';
import { ChevronDownIcon } from '../../icons/chevronDown';

interface Props {
    onChange: (e: MultiSelectChangeEvent) => void
    value: { label: string; value: string; }[]
    option: { label: string, value: string }[]
    placeholder?: string
    disabled?: boolean
    error?: boolean
    errorLabel?: string
    selectAllLabel: string
}

export default function MultiSelectCheckbox(props: Props) {
    const { onChange } = props;
    const { value } = props;
    const { option } = props;
    const { placeholder } = props;
    const { disabled } = props;

    return (
        <div style={{textAlign: 'left'}}>
            <MultiSelect
                dropdownIcon={<ChevronDownIcon size={20} color={colors.neutral[300]} />}
                value={value}
                onChange={onChange}
                options={option}
                optionLabel="label"
                placeholder={placeholder}
                maxSelectedLabels={6}
                className="w-full"
                checkboxIcon={<CheckedIcon size={20} color={colors.white} />}
                itemCheckboxIcon={<CheckedIcon size={20} color={colors.white} />}
                selectAllLabel={props.selectAllLabel}
                emptyMessage={"Non è disponibile nessuna opzione"}
                selectedItemsLabel="{0} selezionati"
                disabled={disabled}
            />
            {
                props.error &&
                <span style={{color: 'rgba(253, 102, 91, 1)'}} className="multiselect-error-text">{props.errorLabel}</span>
            }
        </div>
    );
}