import { Fragment } from "react/jsx-runtime";
import Input from "../../../../ui/molecules/input";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { useEffect, useState } from "react";
import { GetAllRequirements, RequirementUpdate, RequirementValidation, resetRequirementErrors, resetRequirements, resetRequirementUpdateRequest, resetRequirementUpdateStatus, resetRequirementValidationStatus, setUpdateNorm, setUpdateRequirement, setUpdateSpec } from "../slice";
import MultiSelectCheckbox from "../../../../ui/molecules/multiSelectCheckbox";
import { normativa } from "../../../../utils";
import TextArea from "../../../../ui/molecules/textArea";
import Button from "../../../../ui/molecules/button";

interface Props {
    close: () => void
}

export function RequirementPopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const requirementState = useAppSelector(state => state.PMRequirement)
    const [selectedNorma, setSelectedNorma] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        const initialNorma = []
        for(const n of requirementState.requirementUpdateRequest.norm) {
            initialNorma.push(n as any)
        }
        setSelectedNorma(initialNorma)
    },[])

    useEffect(() => {
        if(requirementState.requirementValidationStatus === 'successfully') {
            dispatch(resetRequirementValidationStatus())
            dispatch(resetRequirementErrors())
            dispatch(RequirementUpdate({body: requirementState.requirementUpdateRequest, id: requirementState.requirementIdToUpdate}))
        }
    }, [requirementState.requirementValidationStatus])

    useEffect(() => {
        if(requirementState.requirementUpdateStatus === 'successfully') {
            dispatch(resetRequirementUpdateStatus())
            dispatch(resetRequirementUpdateRequest())
            dispatch(resetRequirements())
            dispatch(GetAllRequirements())
            props.close()
        }
    }, [requirementState.requirementUpdateStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>

                {/* FIRST ROW */}
                <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={requirementState.requirementErrors.requirement}
                            supportingText={requirementState.requirementErrors.requirement ? 'Nome mancante!' : undefined}
                            label={'Titolo del requisito'}
                            placeholder="Specificare il nome del requisito"
                            value={requirementState.requirementUpdateRequest.requirement}
                            defaultValue={requirementState.requirementUpdateRequest.requirement}
                            onChangeText={(text) => {dispatch(setUpdateRequirement(text))}}
                            />
                    </div>
                </div>

                {/* SECOND ROW */} {/* TO CHANGE WITH MULTISELECT */}
                <div className="formAnagrafica pb-5 pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px', borderBottomWidth: 1, }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Norma di riferimento</span>
                        </div>
                        <MultiSelectCheckbox
                            error={requirementState.requirementErrors.norm}
                            errorLabel="Non è presente nessuna normativa!"
                            onChange={(e) => {
                                setSelectedNorma(e.value)
                                dispatch(setUpdateNorm(e.value))
                            }}
                            value={selectedNorma}
                            option={normativa}
                            placeholder="Norma di riferimento"
                            selectAllLabel="Tutte"
                        />
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className="formAnagrafica mt-3 pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Specifiche del requisito</span>
                        </div>
                        <TextArea
                            placeholder="Descrivi le specifiche del requisito"
                            value={requirementState.requirementUpdateRequest.spec}
                            defaultValue={requirementState.requirementUpdateRequest.spec}
                            error={requirementState.requirementErrors.spec}
                            supportingText={requirementState.requirementErrors.spec ? 'Specifiche mancanti!' : undefined} 
                            onChangeText={(text) => dispatch(setUpdateSpec(text))} />
                    </div>
                </div>

            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetRequirementUpdateRequest())
                        dispatch(resetRequirementErrors())
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(RequirementValidation(requirementState.requirementUpdateRequest))
                        }}
                        />
                </div>
            </div>
        </Fragment>
    )
}